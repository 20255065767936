<template>
    <v-card class="ma-5">
        <v-form v-model="valid" ref="form">
            <v-container>
                <v-row class="my-4 align-center">
                    <h1 class="ms-2">FICHA COMUNIÓN</h1>
                    <v-spacer></v-spacer>
                    <v-btn to="/listado" color="primary" class="me-2"><v-icon>mdi-arrow-left</v-icon>VOLVER</v-btn>
                    <v-btn @click="duplicate" color="info" class="me-2"><v-icon>mdi-clipboard-text-clock-outline</v-icon>DUPLICAR FECHA</v-btn>
                    <v-btn @click="dupEspacios" color="info" class="me-2"><v-icon>mdi-home-group-plus</v-icon>DUPLICAR ESPACIO</v-btn>
                    <v-btn v-if="idAdHoc" @click="del" color="error" class="me-2"><v-icon>mdi-trash-can-outline</v-icon>ELIMINAR</v-btn>
                </v-row>
                <v-divider></v-divider>
                <h3><b style="color: var(--v-primary-base)">FECHA</b></h3>
                <v-divider></v-divider>
                <AuxInput class="mx-2 no-gutters" title="DÍA">
                    <v-menu ref="menu" v-model="menu" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="[rules.req]" :value='plantilla.dia != null ? ((new Date(plantilla.dia).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(plantilla.dia).getDate()).slice(-2)) + (new Date(plantilla.dia).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(plantilla.dia).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu = false" no-title v-model="plantilla.dia" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="HORARIO">
                    <v-select :rules="[rules.req]" filled v-model="plantilla.horario" dense hide-details :items="horarios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-divider class="mt-5"></v-divider>
                <h3><b style="color: var(--v-primary-base)">CONTACTO</b></h3>
                <v-divider></v-divider>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE COMULGANTE">
                    <v-text-field :rules="[rules.req]" filled v-model="plantilla.nino" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE Y APELLIDOS PADRE">
                    <v-text-field :rules="[rules.req]" filled v-model="plantilla.nombrePadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE Y APELLIDOS MADRE">
                    <v-text-field filled v-model="plantilla.nombreMadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="BREVE DATO">
                    <v-text-field filled v-model="plantilla.breveDato" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TELÉFONO PADRE">
                    <v-text-field :rules="[rules.req]" filled v-model="plantilla.tlfPadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TELÉFONO MADRE">
                    <v-text-field filled v-model="plantilla.tlfMadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EMAIL PADRE">
                    <v-text-field :rules="[rules.req]" filled v-model="plantilla.emailPadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EMAIL MADRE">
                    <v-text-field filled v-model="plantilla.emailMadre" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CIUDAD RESIDENCIA">
                    <v-text-field filled v-model="plantilla.ciudadResidencia" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="PARROQUIA COMUNIÓN">
                    <v-text-field filled v-model="plantilla.parroquia" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ORIGEN RESERVA">
                    <v-select filled v-model="plantilla.origenReserva" dense hide-details :items="origenesReserva" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-divider class="mt-5"></v-divider>
                <h3><b style="color: var(--v-primary-base)">TIPO COMUNIÓN</b></h3>
                <v-divider></v-divider>
                <AuxInput class="mx-2 no-gutters" title="TIPO COMUNIÓN">
                    <v-select filled v-model="plantilla.tipoComunion" dense hide-details :items="tipoComunion" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ADULTOS">
                    <v-text-field :rules="ruleNum" filled v-model="plantilla.adultos" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NIÑOS">
                    <v-text-field :rules="ruleNum" filled v-model="plantilla.ninos" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <v-divider class="mt-5"></v-divider>
                <h3><b style="color: var(--v-primary-base)">ESPACIOS</b></h3>
                <v-divider></v-divider>
                <AuxInput class="mx-2 no-gutters" title="ESPACIO">
                    <v-select :rules="[rules.req]" @change="checkOtros" filled v-model="plantilla.espacio" dense hide-details :items="espacios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput v-if="plantilla.espacio == 7 || plantilla.espacio == 6" class="mx-2 no-gutters" title="CIUDAD CAT">
                    <v-text-field filled v-model="plantilla.espacioDetallado" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="SALÓN">
                    <v-select placeholder="Sin detallar" clearable filled v-model="plantilla.salon" dense hide-details :items="salones" :item-text="item => item.title" :item-value="item => item.idSalon" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="SALÓN EXCLUSIVO">
                    <v-checkbox class="my-2" v-model="plantilla.excl" color="primary" hide-details></v-checkbox>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="APERITIVO">
                    <v-select clearable filled v-model="plantilla.aperitivo" dense hide-details :items="aperitivos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-divider class="mt-5"></v-divider>
                <h3><b style="color: var(--v-primary-base)">OTROS</b></h3>
                <v-divider></v-divider>
                <AuxInput class="mx-2 no-gutters" title="QUIEN RESERVA">
                    <v-select filled v-model="plantilla.reserva" dense hide-details :items="reserva" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="OBSERVACIONES">
                    <v-textarea v-model="plantilla.observaciones" filled dense hide-details="auto" no-resize></v-textarea>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NECESIDAD HABITACIONES">
                    <v-checkbox class="my-2" v-model="plantilla.habitaciones" color="primary" hide-details></v-checkbox>
                </AuxInput>
                <v-row>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-divider></v-divider>
                        <h3 style="color: var(--v-primary-base)"><b>ESTADO</b></h3>
                        <v-divider></v-divider>
                        <v-radio-group v-model="plantilla.estado">
                            <v-radio
                                v-for="n in estados"
                                :key="n.val"
                                :label="n.title"
                                :value="n.val"
                            >
                            </v-radio>
                        </v-radio-group>
                        <template v-if="plantilla.estado == 3">
                            <v-select filled v-model="plantilla.perdida" dense hide-details :items="['ESPACIO','PRECIO','COMPETENCIA CONOCIDA','UBICACIÓN','OTROS']" class="v-superdense-input"></v-select>
                            <v-textarea v-model="plantilla.motivoPerdida" filled dense hide-details="auto" no-resize></v-textarea>
                        </template>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-divider></v-divider>
                        <h3 style="color: var(--v-primary-base)"><b>DEPÓSITO CONFIRMACIÓN</b></h3>
                        <v-divider></v-divider>
                        <v-checkbox class="my-2" label="PAGO FIANZA"
                            v-model="plantilla.fianza" color="primary" hide-details></v-checkbox>
                        <v-checkbox class="my-2" label="FACTURA FIANZA"
                            v-model="plantilla.facturaFianza" color="primary" hide-details></v-checkbox>
                        <v-checkbox class="my-2" label="PRESUPUESTO"
                            v-model="plantilla.presupuesto" color="primary" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0">
                        <v-divider></v-divider>
                        <h3 style="color: var(--v-primary-base)"><b>FEEDBACK</b></h3>
                        <v-divider></v-divider>
                        <v-radio-group v-model="plantilla.feedback">
                            <v-radio
                                v-for="n in feedback"
                                :key="n.val"
                                :label="n.title"
                                :value="n.val"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-btn color="primary" class="my-2" @click="save"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn>
            </v-container>
        </v-form>
        <ConfirmDialog ref="confirm" />
        <SelectDates ref="duplicar" />
        <SelectEspacios ref="dupEspacio" :esps="espacios" :e="plantilla.espacio" />
    </v-card>
</template>


<script>

import { req } from "@/utils/validations.js";
export default {
    props: ["idAdHoc"],
    components: {
        TableFilters: () => import("@/components/TableFilters.vue"),
        AuxInput: () => import("@/components/AuxInput.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
        SelectDates: () => import("@/components/SelectDates.vue"),
        SelectEspacios: () => import("@/components/SelectEspacios.vue"),
    },
    computed: {
        auxModel: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
    data() {
        return {
            introduce:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"BELEN", val : 10},
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6}, 
                {title:"OTROS", val : 7}],
            realiza:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"BELEN", val : 10},
                {title:"MONICA", val : 4}, 
                {title:"OTROS", val : 7}],
            feedback:[
                {title:"NS / NC", val : 1}, 
                {title:"Valoración Negativa", val : 2}, 
                {title:"Valoración Neutra", val : 3},
                {title:"Valoración Positiva", val : 4}, 
            ],
            espDupes: [],
            rules: { req },
            ruleNum:[ v => (!isNaN(v) || !!v) || 'Introduce un número'],
            saving: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fechasDuplicado: [],
            presupuesto: {
                fecha: null,
                estado: null,
                confirmado: null,
                fechaEv: null,
                tipo: null,
                cliente: null,
                espacio: null,
                ubicacion: null,
                tipoPpto: null,
                introduce: null,
                realiza: null,
                supervisa: null,
                notas: null,
            },
            horarios:[
                {title:"CO",val:1}, 
                {title:"CE",val:2},
                {title:"PTE",val:9}],
            origenesReserva:[
                {title:"EXPERIENCIA", val : 2}, 
                {title:"RECOMENDACIÓN", val : 3}, 
                {title:"RRSS o PRENSA", val : 1},
                {title:"OTROS", val : 5}],
            tipoComunion:[
                {title:"CLÁSICA", val : 1}, 
                {title:"INFORMAL", val : 2}],
            aperitivos:[
                {title:"EN DUDA", val : 1}, 
                {title:"SIN APTVO", val : 2}, 
                {title:"JARDÍN", val : 3}, 
                {title:"TERRAZA HOTEL", val : 4}, 
                {title:"TERRAZA LC", val : 5},
                {title:"LALANNE", val : 6},
                {title:"EMBARCADERO", val : 7},
                {title:"OTROS", val : 8},],
            espacios:[
                {title: "PTE", val : 0},
                {title: "PISCIS", val : 1},
                {title: "HOTEL", val : 2}, 
                {title: "LALANNE", val : 3}, 
                {title: "LAUS", val : 4}, 
                {title: "PALACIO", val : 5}, 
                {title: "MAS DE PONENT", val : 16}, 
                {title: "LLOTJA", val : 8},
                {title: "VDV", val : 9}, 
                {title: "PANTICOSA", val : 50}, 
                {title: "SOMMOS", val : 60}, 
                {title: "CATERING ARAGÓN", val : 6}, 
                {title: "CATERING CATALUÑA", val : 7}
            ],
            salones: [
                {idSalon:1, idEspacio: 1, title : "ROSA"},
                {idSalon:2, idEspacio: 1, title : "ROYAL"},
                {idSalon:3, idEspacio: 1, title : "CONTINENTAL"},
                {idSalon:4, idEspacio: 2, title : "LA CUCHARILLA"},
                {idSalon:5, idEspacio: 2, title : "MULTIUSOS"},
                {idSalon:6, idEspacio: 2, title : "PISTAS FLORIDA"},
                {idSalon:7, idEspacio: 2, title : "CLASH"},
                {idSalon:11, idEspacio: 2, title : "MIRADOR"},
                {idSalon:17, idEspacio: 1, title : "Pendiente Asignar"},
                {idSalon:18, idEspacio: 2, title : "Pendiente Asignar"},
                {idSalon:19, idEspacio: 2, title : "PISTAS Y MIRADOR"},
                {idSalon:20, idEspacio: 2, title : "CLASH Y MIRADOR"},
                {idSalon:21, idEspacio: 1, title : "EL JARDIN"},
                {idSalon:22, idEspacio: 1, title : "EL JARDIN + ROYAL"},
                {idSalon:23, idEspacio: 1, title : "EL JARDIN + CONT"},
                {idSalon:24, idEspacio: 2, title : "TERRAZA SAM"},
                {idSalon:25, idEspacio: 2, title : "LA CUCHARILLA + TERRAZA LC"},
                {idSalon:26, idEspacio: 2, title : "CLASH Y TERRAZA SAM"},
                {idSalon:27, idEspacio: 2, title : "CLASH Y TERRAZA LC"},
                {idSalon:28, idEspacio: 1, title : "ROSA o ROYAL"},
                {idSalon:29, idEspacio: 1, title : "ROYAL O CONTINENTAL"},
                {idSalon:30, idEspacio: 1, title : "ROSA o CONTINENTAL"},
                {idSalon:31, idEspacio: 1, title : "JARDÍN o ROSA"},
                {idSalon:32, idEspacio: 2, title : "PLANTA 1ª"},
                {idSalon:33, idEspacio: 2, title : "LA CUCHARILLA o MULTIUSOS"},
                {idSalon:38, idEspacio: 2, title : "LA CUCHARILLA o PISTAS"},
                {idSalon:39, idEspacio: 2, title : "MULTI / PISTAS"},
                {idSalon:40, idEspacio: 2, title : "PISTAS / CLASH"},
                {idSalon:43, idEspacio: 8, title : "MULTIFUNCIONAL"},
                {idSalon:44, idEspacio: 8, title : "FOYER PRINCIPAL"},
            ],
            reserva:[
                {title:"MONICA", val : 1}, 
                {title:"ISABEL", val : 2}, 
                {title:"LAURA", val : 3}, 
                {title:"BERTA", val : 4}, 
                {title:"BELEN", val : 10},
                {title:"JAVI", val : 5}, 
                {title:"OTROS", val : 6}],
            estados:[
                {title:"CONFIRMADA", val : 2}, 
                {title:"PENDIENTE", val : 1}, 
                {title:"PERDIDA", val : 3},
                {title:"ELIMINADA", val : 5},
            ],
            menu: false,
            valid: true,
            plantilla: {
                dia: null,
                horario: null,
                nino: null,
                nombrePadre: null,
                nombreMadre: null,
                breveDato: null,
                tlfPadre: null,
                tlfMadre: null,
                emailPadre: null,
                emailMadre: null,
                ciudadResidencia: null,
                parroquia: null,
                origenReserva: null,
                tipoComunion: null,
                adultos: null,
                ninos: null,
                espacio: null,
                espacioDetallado: null,
                salon: null,
                excl: null,
                aperitivo: null,
                reserva: null,
                observaciones: null,
                estado: 1,
                perdida: null,
                motivoPerdida: null,
                habitaciones: false,
                fianza: false,
                facturaFianza: false,
                presupuesto: false,
                feedback: 1,
            }
        };
    },
    methods:{
        async del(){
            const confirmation =
                (await this.$refs.confirm.open(
                "Eliminar ficha",
                "También se eliminará el evento asociado a esta ficha. Esta acción NO se puede deshacer.",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/listado/borrar/${this.idAdHoc}`,
                    })
                this.saving = false;
                this.$root.$emit("snack", "Eliminado correctamente");
                this.$router.push({ name: 'listado'}).catch(()=>{})
            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido eliminar correctamente");
            }
        },
        checkOtros () {
            if (this.plantilla.espacio == 6 || this.plantilla.espacio == 7){
                this.plantilla.salon = null
            }
            else{
                this.plantilla.espacioDetallado = null
            }
        },
        async save(skip) {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                skip ||
                (await this.$refs.confirm.open(
                "Guardar los cambios",
                "¿Estás seguro de que quieres guardar este evento?",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/listado/comunion`,
                        data: {
                            items: JSON.stringify({datos : this.plantilla, idAdHoc : this.idAdHoc }),
                        }
                    })
                this.saving = false;

                if(false){
                // if(this.plantilla.presupuesto){
                    this.presupuesto.fecha = new Date().toISOString().split('T')[0]
                    this.presupuesto.estado = 2
                    this.presupuesto.confirmado = 0
                    this.presupuesto.fechaEv = this.plantilla.dia
                    this.presupuesto.tipo = 2
                    this.presupuesto.cliente = (("COM " + this.plantilla.nino) || '')
                    this.presupuesto.espacio = [6,7].includes(this.plantilla.espacio) ? 10 : this.plantilla.espacio
                    if (this.plantilla.espacio == 6 || this.plantilla.espacio == 7) {
                        this.presupuesto.ubicacion = this.plantilla.espacioDetallado
                    }
                    else{
                        this.presupuesto.ubicacion = this.espacios.filter(e => e.val == this.plantilla.espacio)[0].title
                    }
                    this.presupuesto.tipoPpto = 1
                    this.presupuesto.supervisa = 10

                    const res = await axios({
                        url: "/presupuestos/nuevo",
                        method: "POST",
                        data: {
                        items: JSON.stringify({
                            idPresupuesto: this.idPresupuesto,
                            idEvento: id,
                            datos: this.presupuesto,
                        })
                        },
                    });
                    this.$root.$emit("snack", "El presupuesto se ha creado correctamente");
                    this.idPresupuesto = res.data
                    }

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ name: 'listado'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async duplicate() {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                (await this.$refs.duplicar.open(
                "Duplicar evento",
                "Marca los días a los que quieras copiar el evento",
                this.plantilla.dia
                ));
            if (!confirmation) 
                return;
            if (confirmation.length == 0){
                this.$root.$emit("snack", "ERROR: Selecciona al menos 1 fecha para duplicar el evento");
                return;
            }
            else this.fechasDuplicado = confirmation;
            try {
                this.saving = true;
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/listado/duplicar/comunion`,
                        data: {
                            items: JSON.stringify({datos : this.plantilla, idAdHoc : this.idAdHoc, fechas : this.fechasDuplicado}),
                        }
                    })
                this.saving = false;

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ name: 'listado'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async dupEspacios() {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                (await this.$refs.dupEspacio.open(
                "Duplicar evento",
                "Marca los espacios a los que quieras copiar el evento",
                this.plantilla.dia
                ));
            if (!confirmation) 
                return;
            if (confirmation.length == 0){
                this.$root.$emit("snack", "ERROR: Selecciona al menos 1 espacio para duplicar el evento");
                return;
            }
            else this.espDupes = confirmation;
            try {
                this.saving = true;
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/listado/dupEspacio/comunion`,
                        data: {
                            items: JSON.stringify({datos : this.plantilla, idAdHoc : this.idAdHoc, espacios : this.espDupes}),
                        }
                    })
                this.saving = false;

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ name: 'listado'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async getAdHoc() {
            if (this.idAdHoc){
                try {
                    const { data } = await axios({
                            method: 'POST',
                            url: `${process.env.VUE_APP_API_URL}/listado/`,
                            data:{
                                items : JSON.stringify({idAdHoc : this.idAdHoc})
                            }
                        })
                    this.$root.$emit("snack", "Evento cargado correctamente");
                    this.plantilla = JSON.parse(data);
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido cargar los datos de este evento");
                }
            }
            else return;
        }
    },
    mounted(){
        this.getAdHoc()
    }
};
</script>